@import 'math';
@import 'colors';


/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} Unitless number
@function strip-unit($number) {
  @if type-of($number)=='number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}


/// It takes a code and generates a css rule for .lens-swatch-{code} class
///
/// @param {String} $code - Number to remove unit from
@mixin lens-swatch ($code) {
  .lens-swatch_#{$code} {
    background-image: url('//images.ray-ban.com/is/image/RayBan/lens-swatch_#{$code}.png?impolicy=RB_?png-full'); // sass-lint:disable-line no-url-protocols
  }
}

/// It generates a retina ready icon from the given sprite at the given position
///
/// @param {String} $path - Url of the sprite image @1x
/// @param {Number} $w - Width in pixels of the sprite background image @1x
/// @param {Number} $h - Height in pixels of the sprite background image @1x
/// @param {Number} $posX - Horizontal position from the top left corner, in negative pixels, of the icon inside the sprite @1x
/// @param {Number} $posY - Vertical position from the top left corner, in negative pixels, of the icon inside the sprite @1x
@mixin icon-from-sprite($path_at1x, $path_at2x, $w, $h, $posX, $posY) {
  background-image: $path_at1x;
  display: inline-block;
  background-position: $posX $posY;

  // media query to apply high-resolution background images (http://www.retinajs.com)
  @media (-webkit-min-device-pixel-ratio: 1.25), (min--moz-device-pixel-ratio: 1.25),
    (-o-min-device-pixel-ratio: 3/2),
    (min-resolution: 1.25dppx) {
      // i must force the background size so that it will be downscaled
      background-image: $path_at2x;
      background-size: $w $h;
  }
}

.lens-light-condition span.wcs-half-left:first-child {
  margin-left: 0 !important;
}

@mixin rb-fieldrow-custom {
  .rb-fieldrow__helper,
  .rb-fieldrow {
    &.rb-floating {
      label {
        color: $greyLighten2;
        line-height: 16.5px;
      }
    }

    &.rb-floating,
    &.rb-validrow {
      line-height: 16.5px !important;
    }

    &.rb-invalidrow {
      label {
        color: #b50900;
      }
    }
  }
  .label_roe-countryName {
    font-size: 75%;
  }
  .rb-fieldrow__helper,
  .invalid-date-error {
    font-size: 12px !important;
    line-height: 1.6;
    font-weight: normal;
  }
  .rb-fieldrow__helper {
    color: $greyLighten2;
  }
  .invalid-date-error {
    color: $darker-red;
    margin-top: 8px;
  }
  .rb-fieldrow__helper--error {
    display: none;
    color: #b50900;
  }

  .rb-invalidrow .rb-fieldrow__helper--error {
    display: block;
    font-weight: normal;
    
    & + .rb-fieldrow__helper {
      display: none;
    }
  }
  
  .restriction-help {
    .rb-fieldrow__details {
      color: $black;
    }

    .rule-valid {
      color: $black!important;
      font-weight: $font-weight-bold;
  
      .icon-valid {
        display: inline-block;
      }
    }
  }

  .rb-invalidrow .restriction-help {
    :not(.rule-valid) .icon-invalid {
      display: inline-block;
    }
    .rb-fieldrow__details {
      display: none;
    }
  } 

  .icon {
    display: none;
    background-repeat: no-repeat;
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    &-valid {
      background: url('/wcsstore/RayBanStorefrontAssetStore/desktop/assets/icons/icon-valid.svg');
    }
    &-invalid {
      background: url('/wcsstore/RayBanStorefrontAssetStore/desktop/assets/icons/icon-invalid.svg');
    }
  }

  .rb-fieldrow__optional {
    font-family: $font-family;
    font-size: inherit;
    color: $greyLighten2;
    &::before {
      display: inline-block;
      content: '-';
      padding: 0 2px;
    }
  }
}

@mixin rb-fieldrow-checkbox-custom {
  .rb-fieldrow-checkbox {
    span {
      color: $primary-color !important;
    }
    &__title {
      font-family: $font-bold;
      font-weight: 400;
      text-transform: uppercase;
    }
    &__desc {
      font-family: $font-family;
      font-size: 13px;
      font-weight: $font-weight-light;
      line-height: 1.58;
      color: $primary-color;
      cursor: default;
    }
  }
}
