/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */

@import '../../common/variables';

/// Set internal color variables
$bxslider-caption-bg: rgba(80, 80, 80, 0.75) !default;

/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	margin: 0 auto 60px;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	max-width: 100%;
	display: block;
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
	// -moz-box-shadow: 0 0 5px get-color('grey');
	// -webkit-box-shadow: 0 0 5px get-color('grey');
	// box-shadow: 0 0 5px get-color('grey');
	// border: solid get-color('white') 5px;
	left: 0;
	background:$white;
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 1px;
	background: url(../icons/bx_loader.gif) center center no-repeat get-color('white');
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: $font-regular;
	font-weight: bold;
	color: get-color('greyLighten2');
	padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: get-color('greyLighten2');
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: get-color('black');
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: 0;
	background: $sprite-desktop no-repeat -182px -283px;
	width:15px;
	height:25px;
}

.bx-wrapper .bx-next {
	right: 0;
	background: $sprite-desktop no-repeat -178px -250px;
	width:15px;
	height:25px;
}

.bx-wrapper .bx-prev:hover {
	background-position: -182px -283px;
}

.bx-wrapper .bx-next:hover {
	background-position: -178px -250px;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	text-indent: -9999px;
	z-index: 2 !important;
}

.bx-wrapper .bx-controls-direction a.bx-prev.disabled {
	background-position: -88px -284px;
}
.bx-wrapper .bx-controls-direction a.bx-next.disabled {
	background-position: -106px -250px;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	background: url(../icons/controls.png) -86px -11px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	background: url(../icons/controls.png) -86px -44px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: get-color('greyLighten2')\9;
	background: $bxslider-caption-bg;
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: get-color('white');
	font-family: $font-regular;
	display: block;
	font-size: .85em;
	padding: 10px;
}

/* customized styles for Remix product configurator carousel */
.wcs-h-slider-wrapper .bx-wrapper .bx-viewport{
	box-shadow:none;
	border: 0;
}
.wcs-h-slider-wrapper .bx-wrapper .bx-controls-direction a{
	z-index:1;
}
.wcs-h-slider-wrapper .bx-wrapper img{
	margin:0 auto;
}
.wcs-h-slider-wrapper .wcs-h-slide{
	text-align:center;
}
.wcs-h-slider-wrapper .bx-wrapper .bx-controls-direction a.bx-prev{
	left:-17px;
}.wcs-h-slider-wrapper .bx-wrapper .bx-controls-direction a.bx-next{
	right:-17px;
}
#ratingsSection{clear:both !important;}
