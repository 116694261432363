////
/// Variables
/// list of variables and properties widely used on Ray-ban Toolkit.
/// @group Common
/// @author Ray-ban Team.
////




/// import default utilities
@import 'utils';

/// Base image path
///
/// usage:
///    src: url('#{$rb-image-path}/path/to/image/my-image.jps');
///
/// @type string
$rb-image-path: '../images/' !default;

/// Base fonts path :
///
/// usage:
///    src: url('#{$rb-font-path}/sub/path/to/file/my-font.eot');
///
/// @type string
$rb-font-path: '../fonts/' !default;

/// caching browser :
///
/// usage:
///     need to give a directive to the user's browser to update the old sprite image stored locally.
///
$rb-sprite-version: '?ver=7.0' !default;

$white:        #fff !default;
$ligthGrey:    #ededed !default;
$grey-7:       #dadada !default;
$grey-6:       #e6e6e6 !default;
$grey-5:       #d6d6d6 !default;
$grey:         #ccc !default;
$grey-2:       #999 !default;
$grey-4:       #888 !default;
$greyLighten2: #666666 !default;
$grey-3:       #1f1f24 !default;
$black:        #1f1f24 !default;
$red:          #DE0B00 !default;
$darker-red:  #b50900 !default;
$red-2:        #D40B00 !default;
$gold:         #8f7041 !default;
$gold-2:       #83663b !default;
$light-gold:   #bf9456 !default;
$blue:         #2f4bd6 !default;
$green:        #1c8846 !default;
$darkBlue:     #1a1d2a !default;
$focus-color:  #c1e0fe !default;
$csr-btn-blue: #5472be !default;

// New default colors for Redesign
$primary-color: #1f1f24;
$secondary-color: #8c8c8d;
$primary-light-dark-color: #1b1b1b !default;

$light-grey: #e2e2e5;
$light-grey-2: #f2f2f2;
$tile-layout-color: #f2f2f2;
$wcs-grey-1: #e5e5e5 !default;
$wcs-grey-2: #ececec !default;
$light-drown:  #fcf8e3;
$grey-8: #d0d0d0 !default;

/// Color Palette
/// @type Map
$rb-colors: (
  'white': $white,
  'ligthGrey': $ligthGrey,
  'wcs-grey-2': $wcs-grey-2,
  'wcs-grey-1': $wcs-grey-1,
  'grey-6': $grey-6,
  'grey-5': $grey-5,
  'grey-7': $grey-7,
  'grey': $grey,
  'grey-2': $grey-2,
  'grey-4': $grey-4,
  'greyLighten2': $greyLighten2,
  'grey-3': $grey-3,
  'black': $black,
  'red': $red,
  'red-2': $red-2,
  'gold': $gold,
  'gold-2': $gold-2,
  'light-gold': $light-gold,
  'blue': $blue,
  'green': $green,
  'darkBlue': $darkBlue,
  'csr-btn-blue': $csr-btn-blue,
) !default;

/// Fonts
/// Font, line-height, font weight and style for body text, headings, and more.

$font-family: 'Lato', 'Helvetica' !default;
$font-family-2: 'Lato', 'Arial' !default;
$font-regular: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-bold: 'Oswald', 'HelveticaNeueBold', 'Helvetica Neue', 'TeXGyreHerosBold', 'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif !default;

/// Base font size
$base-font-size: 1rem !default;                       // 16px

$large-font-size: 2  * $base-font-size !default;      // 32px
$big-font-size: 1.5 * $base-font-size !default;       // 24px
$medium-font-size: 1.125 * $base-font-size !default;  // 18px
$small-font-size: 0.875 * $base-font-size !default;   // 14px
$xs-font-size: 0.75 * $base-font-size !default;       // 12px

/// Headings font size
$h1-font-size: 2.750 * $base-font-size !default;      // 46px
$h2-font-size: 1.750 * $base-font-size !default;      // 30px
$h3-font-size: 1.625 * $base-font-size !default;       // 28px
$h4-font-size: 1.188 * $base-font-size !default;      // 21px
$h5-font-size: 0.875rem !default;                     // 16px
$h6-font-size: 0.750rem !default;                     // 14px

/// Font weight
$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;
$font-weight-base: $font-weight-normal !default;
$font-weight-oswald: 500 !default;

/// Line Height
$base-line-height:  1.6rem !default;   // 25px
$small-line-height:  1rem !default;    // 16px

/// Font style
$stroke-text-1:  -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black !default;
$stroke-text-2:  -2px -1px 0 $black, 2px -1px 0 $black, -2px 1px 0 $black, 2px 1px 0 $black !default;

$text-shadow-base: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black !default;

/// Components font size
$label-font-size: 1rem !default;   // 16px
$button-font-size: 1rem !default;   // 16px


/// Components
/// Define common spacing for padding and margin, border sizes and more.

/// Base Spaces
$space-base: 1rem !default;          // 16px
$space-1: $space-base * 0.25 !default; // 4px
$space-2: $space-base * 0.5 !default;  // 8px
$space-3: $space-base * 0.75 !default; // 12px
$space-4: $space-base * 1.5 !default; // 24px
$space-5: $space-base * 3 !default;   // 48px

/// Border style
$border-width: 1px solid !default;
$border-color: $grey !default;
$border-base: $border-width $border-color !default;

$border-color-light: lighten($border-color, 10%) !default;
$border-color-dark: darken($border-color, 10%) !default;

$border-radius:  50% !default;
$button-border-width: 2px solid !default;

// Default Remix Icon
$remix-icon: url('#{$rb-image-path}logo-remix.svg') !default;

/// Default transition
//  @type List
$transition-base: all 0.2s ease-in-out !default;

// Outline focus
$focus-base-outline: $focus-color solid 3px !default;

/// Sprite info for desktop
/// @type URL
$sprite-desktop: url('#{$rb-image-path}spritesheet.png') !default;
$rr-sprite-desktop: url('#{$rb-image-path}rr-sprite.png#{$rb-sprite-version}') !default;
$rr-sprite-desktop-2x: url('#{$rb-image-path}rr-sprite@2x.png#{$rb-sprite-version}') !default;
$rr-sprite-desktop-width: 300px; //@1x
$rr-sprite-desktop-height: 300px; //@1x

/// Sprite info for mobile
/// @type URL
$sprite-mobile: url('#{$rb-image-path}sprite.png') !default;
$rr-sprite-mobile: url('#{$rb-image-path}rr-sprite.png#{$rb-sprite-version}') !default;
$rr-sprite-mobile-2x: url('#{$rb-image-path}rr-sprite@2x.png#{$rb-sprite-version}') !default;
$rr-sprite-mobile-width: 350px; //@1x
$rr-sprite-mobile-height: 350px; //@1x
$rr-sprite-mobile-width-afterpay-less-or-more: 315px; //@1x
$rr-sprite-mobile-height-afterpay-less-or-more: 315px; //@1x

$cart-desktop-breakpoint: 1250px;
